
import {
  defineComponent,
  computed,
  toRefs
} from 'vue'
import { alphabet } from '@/definitions/_general/_data/alphabet'

const buttonsLabel = ['#', ...alphabet]

export default defineComponent({
  name: 'ContactsListsFromExistingContactsAside',
  props: {
    modelValue: {
      type: String,
      required: true,
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const { modelValue } = toRefs(props)

    const selectValue = computed({
      get(): string {
        return modelValue.value
      },
      set(val: string) {
        emit('update:modelValue', val)
      },
    })

    return {
      selectValue,
      buttonsLabel,
    }
  },
})
