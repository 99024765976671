
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import TopFilter from '@/components/layout/topFilter/TopFilter.vue'
import type { ContactListsDetails } from '@/definitions/contacts/lists/types'
import TopFilterButton from '@/components/layout/topFilter/TopFilterButton.vue'
import useContactsOpenModal from '@/compositions/contacts/useContactsOpenModal'

export default defineComponent({
  name: 'ContactsListsFromExistingContactsFilter',
  components: {
    TopFilter,
    TopFilterButton,
  },
  props: {
    selected: {
      type: Array as PropType<ContactListsDetails[]>,
      required: true,
    },
    addButtonLabel: {
      type: String,
      default: 'contacts',
    },
  },
  setup() {
    const { openModalNewContact } = useContactsOpenModal()

    return {
      openModalNewContact,
    }
  },
})
