import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_add_existing_contacts_filter = _resolveComponent("add-existing-contacts-filter")!
  const _component_add_existing_contacts_table = _resolveComponent("add-existing-contacts-table")!
  const _component_add_existing_contacts_aside = _resolveComponent("add-existing-contacts-aside")!
  const _component_tm_modal_overlay_scrollable = _resolveComponent("tm-modal-overlay-scrollable")!

  return (_openBlock(), _createBlock(_component_tm_modal_overlay_scrollable, {
    id: "addExistingContacts",
    "modal-title": "Contacts",
    size: "xLarge",
    "no-footer": "",
    "left-aside-width": "28px",
    "min-height": "695px"
  }, {
    "modal-content": _withCtx(() => [
      _createVNode(_component_add_existing_contacts_filter, {
        selected: _ctx.selected,
        "add-button-label": _ctx.addButtonLabel
      }, null, 8, ["selected", "add-button-label"]),
      _createVNode(_component_add_existing_contacts_table, {
        modelValue: _ctx.selected,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selected) = $event)),
        headers: _ctx.tableHeaders,
        items: _ctx.tableItemsFilter,
        "item-key": "id",
        "hide-actions": "",
        "items-name": "contacts",
        "show-selection": ""
      }, null, 8, ["modelValue", "headers", "items"])
    ]),
    aside: _withCtx(() => [
      _createVNode(_component_add_existing_contacts_aside, {
        modelValue: _ctx.selectAlphabet,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectAlphabet) = $event))
      }, null, 8, ["modelValue"])
    ]),
    _: 1
  }))
}