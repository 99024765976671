
import { defineComponent, nextTick, ref } from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'

export default defineComponent({
  components: { TmModal },
  props: {
    id: {
      type: String,
    },
  },
  setup() {
    const modal = ref()
    const overlayListener = (e: Event) => {
      if (e.target === getOverlay()) {
        modal.value.hideModal()
      }
    }
    const getOverlay = () => {
      return document.querySelector('.tm-dialog--scrollable .q-dialog__inner')!
    }

    const onShow = async () => {
      await nextTick()
      getOverlay().addEventListener('click', overlayListener)
    }
    const onHide = () => {
      getOverlay().removeEventListener('click', overlayListener)
    }
    const scroll = (element: HTMLElement) => {
      getOverlay().scrollTo({ top: element.offsetTop })
    }

    return {
      modal,
      onShow,
      onHide,
      scroll,
    }
  },
})
