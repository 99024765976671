
import {
  defineComponent,
  ref,
  computed
} from 'vue'
import AddExistingContactsTable from '@/components/modals/contacts/shared/addExistingContacts/AddExistingContactsTable.vue'
import AddExistingContactsFilter from '@/components/modals/contacts/shared/addExistingContacts/AddExistingContactsFilter.vue'
import AddExistingContactsAside from '@/components/modals/contacts/shared/addExistingContacts/AddExistingContactsAside.vue'
import type { ContactListsDetails, ContactListsDetailsFilter } from '@/definitions/contacts/lists/types'
import type { TableHeaders } from '@/definitions/shared/types'
import { getTableData } from '@/services/tableService'
import TmModalOverlayScrollable from '@/components/shared/modal/TmModalOverlayScrollable.vue'

export default defineComponent({
  components: {
    TmModalOverlayScrollable,
    AddExistingContactsTable,
    AddExistingContactsFilter,
    AddExistingContactsAside,
  },
  props: {
    addButtonLabel: {
      type: String,
    },
  },
  setup() {
    const selectAlphabet = ref('#')

    const selected = ref<ContactListsDetails[]>([])

    const tableHeaders = ref<TableHeaders[]>([
      { text: 'Name', value: 'full-name-slot' },
      { text: 'Phone', value: 'phone' },
      { text: 'Country', value: 'country-slot' },
    ])

    const tableItems = ref<ContactListsDetailsFilter[]>(getTableData('contactsListsDetails')
      .map((el: ContactListsDetails) => ({
        ...el,
        nameFirstSymbol: el.person.fullName[0].toUpperCase(),
      })))

    const tableItemsFilter = computed<ContactListsDetailsFilter[]>(() => {
      if (selectAlphabet.value === '#') return tableItems.value
      return tableItems.value.filter((el) => (el.nameFirstSymbol === selectAlphabet.value))
    })

    return {
      selectAlphabet,
      selected,
      tableHeaders,
      tableItems,
      tableItemsFilter,
    }
  },
})
