
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import TmTable from '@/components/shared/table/TmTable.vue'
import TmPerson from '@/components/shared/TmPerson.vue'
import TmCountry from '@/components/shared/TmCountry.vue'
import type { TableHeaders } from '@/definitions/shared/types'
import type { ContactListsDetails } from '@/definitions/contacts/lists/types'

export default defineComponent({
  name: 'ContactsListsFromExistingContactsTable',
  components: {
    TmTable,
    TmPerson,
    TmCountry,
  },
  props: {
    headers: {
      type: Array as PropType<TableHeaders[]>,
      required: true,
    },
    items: {
      type: Array as PropType<ContactListsDetails[]>,
      required: true,
    },
    itemsName: {
      type: String,
      required: true,
    },
    itemKey: {
      type: String,
      default: 'id',
    },
    showSelection: {
      type: Boolean,
    },
  },
})
