import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelRadio as _vModelRadio, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-accbd7f0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "aside-container" }
const _hoisted_2 = ["id", "value"]
const _hoisted_3 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("aside", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.buttonsLabel, (w) => {
      return (_openBlock(), _createElementBlock(_Fragment, { key: w }, [
        _withDirectives(_createElementVNode("input", {
          id: `existing-contacts-alphabet-${w}`,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectValue) = $event)),
          type: "radio",
          name: "existing-contacts-alphabet",
          class: "visually-hidden",
          value: w
        }, null, 8, _hoisted_2), [
          [_vModelRadio, _ctx.selectValue]
        ]),
        _createElementVNode("label", {
          class: "body-text-semi-bold-14 lh-20",
          for: `existing-contacts-alphabet-${w}`
        }, _toDisplayString(w), 9, _hoisted_3)
      ], 64))
    }), 128))
  ]))
}