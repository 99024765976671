import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_top_filter_button = _resolveComponent("top-filter-button")!
  const _component_top_filter = _resolveComponent("top-filter")!

  return (_openBlock(), _createBlock(_component_top_filter, { selected: _ctx.selected }, {
    "filter-line-left-prepend-permanent": _withCtx(() => [
      _createVNode(_component_top_filter_button, {
        color: "blue",
        text: `Add ${_ctx.selected.length} ${ _ctx.addButtonLabel }`,
        disabled: !_ctx.selected.length
      }, null, 8, ["text", "disabled"]),
      _createVNode(_component_top_filter_button, {
        to: { name: 'base.contacts.importFlow' },
        icon: "tmi-upload",
        text: "Import contacts"
      }, null, 8, ["to"]),
      _createVNode(_component_top_filter_button, {
        icon: "add",
        text: "Create new contact",
        onClick: _ctx.openModalNewContact
      }, null, 8, ["onClick"])
    ]),
    _: 1
  }, 8, ["selected"]))
}